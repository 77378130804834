import { createSlice } from "@reduxjs/toolkit";

const getQueryCondition = (filter) => ({
  AND: [
    {
      _operators: {
        applicationDate: filter.applicationDate
          ? { gt: filter.applicationDate }
          : undefined,
      },
    },
    {
      OR: filter.priority.length
        ? filter.priority.map(condition => ({
          _operators: {
            priority: JSON.parse(condition),
          },
        }))
        : [
          {
            _operators: {
              priority: { gte: -1, lte: 1 },
            },
          },
        ],
    },
    {
      OR: filter.channel.length
        ? filter.channel.map(condition => ({
          _operators: {
            priority: JSON.parse(condition),
          },
        }))
        : [
          {
            _operators: {
              priority: { gte: -1, lte: 1 },
            },
          },
        ],
    },
  ],
});

const initialState = {
  uuid: null,
  tab: "screening",
  view: "list",
  sort: {
    label: "Priority",
    value: "PRIORITY_DESC",
  },
  count: null,
  filter: {
    channel: [],
    applicationDate: undefined,
    priority: [],
    name: undefined,
    location: undefined,
  },
  get queryCondition() {
    return getQueryCondition(this.filter);
  },
  sourcing: {
    count: null,
    data: [],
    page: 0,
    pages: 0,
    offcanvas: {
      activeIndex: null,
      activeId: null,
    },
  },
  screening: {
    count: null,
    data: [],
    page: 0,
    pages: 0,
    popable: {
      hoverIndex: null,
      pinIndex: null,
      tab: "function",
    },
  },
  pageSize: 10,
};

export const distances = createSlice({
  name: "distances",
  initialState,
  reducers: {
    updateUUID: (state, { payload }) => {
      state.uuid = payload;
    },
    updateTab: (state, { payload }) => {
      state.tab = payload;
    },
    updateView: (state, { payload }) => {
      state.view = payload;
    },
    updateSort: (state, { payload }) => {
      state.sort = payload;
    },
    updateCount: (state, { payload }) => {
      state.count = payload;
    },
    updateFilterChannel: (state, { payload }) => {
      const channel = new Set(state.filter.channel);

      if (channel.has(payload)) {
        channel.delete(payload);
      } else {
        channel.add(payload);
      }

      state.filter.channel = [...channel];
      state.queryCondition = getQueryCondition(state.filter);
      state.sourcing.page = 0;
      state.screening.page = 0;
    },
    updateFilterApplicationDate: (state, { payload }) => {
      state.filter.applicationDate = payload;
      state.queryCondition = getQueryCondition(state.filter);
      state.sourcing.page = 0;
      state.screening.page = 0;
    },
    updateFilterPriority: (state, { payload }) => {
      const priority = new Set(state.filter.priority);

      if (priority.has(payload)) {
        priority.delete(payload);
      } else {
        priority.add(payload);
      }

      state.filter.priority = [...priority];
      state.queryCondition = getQueryCondition(state.filter);
      state.sourcing.page = 0;
      state.screening.page = 0;
    },
    updateFilterName: (state, { payload }) => {
      if (payload === "") {
        state.filter.name = undefined;
      } else {
        state.filter.name = payload;
      }
      state.queryCondition = getQueryCondition(state.filter);
      state.sourcing.page = 0;
      state.screening.page = 0;
    },
    updateFilterLocation: (state, { payload }) => {
      state.filter.location = payload;
      state.queryCondition = getQueryCondition(state.filter);
      state.sourcing.page = 0;
      state.screening.page = 0;
    },
    updateApplicantsCount: (state, { payload }) => {
      state.screening.count = payload;
      state.screening.pages = Math.ceil(payload / 10) || 1;
    },
    updateProspectsCount: (state, { payload }) => {
      state.sourcing.count = payload;
      state.sourcing.pages = Math.ceil(payload / 10) || 1;
    },
    updateApplicantsData: (state, { payload }) => {
      state.screening.data = payload;
    },
    updateProspectsData: (state, { payload }) => {
      state.sourcing.data = payload;
    },
    updateApplicantsPage: (state, { payload }) => {
      state.screening.page = payload;
    },
    updateProspectsPage: (state, { payload }) => {
      state.sourcing.page = payload;
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
      state.sourcing.page = 0;
      state.screening.page = 0;
    },
    updateScreeningPopableHover: (state, { payload }) => {
      state.screening.popable.hoverIndex = payload;
    },
    updateScreeningPopablePin: (state, { payload }) => {
      state.screening.popable.pinIndex = payload;
    },
    updateScreeningPopableTab: (state, { payload }) => {
      state.screening.popable.tab = payload;
    },
    updateSourcingOffcanvasActive: (state, { payload }) => {
      state.sourcing.offcanvas.activeIndex = payload;
    },
    updateSourcingOffcanvasId: (state, { payload }) => {
      state.sourcing.offcanvas.activeId = payload;
    },
    resetDistances: (state) => {
      state.uuid = initialState.uuid;
      state.tab = initialState.tab;
      state.view = initialState.view;
      state.sort = initialState.sort;
      state.filter = initialState.filter;
      state.sourcing = initialState.sourcing;
      state.screening = initialState.screening;
      state.pageSize = initialState.pageSize;
    },
  },
});

export const {
  updateUUID,
  updateTab,
  updateView,
  updateSort,
  updateCount,
  updateFilterChannel,
  updateFilterApplicationDate,
  updateFilterPriority,
  updateFilterName,
  updateFilterLocation,
  updateApplicantsCount,
  updateProspectsCount,
  updateApplicantsData,
  updateProspectsData,
  updateApplicantsPage,
  updateProspectsPage,
  updateApplicantsSummary,
  updateProspectsSummary,
  updatePage,
  resetFilter,
  updateScreeningPopableHover,
  updateScreeningPopablePin,
  updateScreeningPopableTab,
  updateSourcingOffcanvasActive,
  updateSourcingOffcanvasId,
  resetDistances,
} = distances.actions;

export default distances.reducer;
