import { createSlice } from "@reduxjs/toolkit";
import { Inbox } from "react-feather";

import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";

const contentBlock = htmlToDraft("");
const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
const editorState = EditorState.createWithContent(contentState);

const initialState = {
  sidebarOpen: false,
  composeOpen: false,
  search: "",
  folderIcons: {
    Inbox: <Inbox size={18} className='me-75' />,
  },
  folders: [],
  activeFolder: 0,
  data: [],
  thread: [],
  replyId: null,
  sendSeparate: false,
  bulkContactList: [],
  form: {
    to: [],
    subject: "",
    cc: [],
    bcc: [],
    content: editorState,
    attachments: [],
  },
  activeIndex: null,
};

export const email = createSlice({
  name: "email",
  initialState,
  reducers: {
    toggleSidebarOpen: (state, { payload }) => {
      state.sidebarOpen = payload;
    },
    toggleComposeOpen: (state, { payload }) => {
      state.composeOpen = payload;

      if (payload === false) {
        state.form = initialState.form;
      }
    },
    updateSearch: (state, { payload }) => {
      state.search = payload;
    },
    updateFolders: (state, { payload }) => {
      const labeledFolders = Object.keys(initialState.folderIcons);

      const labeled = payload.filter(
        x => labeledFolders.includes(x.displayName),
      ).sort((x, y) => {
        if (labeledFolders.indexOf(x.displayName) > labeledFolders.indexOf(y.displayName)) return 1;
        if (labeledFolders.indexOf(x.displayName) < labeledFolders.indexOf(y.displayName)) return -1;
        return 0;
      });

      const unlabeled = payload.filter(
        x => !labeledFolders.includes(x.displayName) && x.unreadItemCount !== 0,
      ).sort((x, y) => {
        if (x.unreadItemCount < y.unreadItemCount) return 1;
        if (x.unreadItemCount > y.unreadItemCount) return -1;
        return 0;
      });

      state.folders = [
        ...labeled,
        ...unlabeled,
      ];
    },
    updateActiveFolder: (state, { payload }) => {
      state.activeFolder = payload;
    },
    updateActiveIndex: (state, { payload }) => {
      state.activeIndex = payload;
    },
    resetData: (state, { payload }) => {
      state.data = [];
    },
    appendData: (state, { payload }) => {
      state.data = [
        ...state.data,
        ...payload,
      ];
    },
    updateThread: (state, { payload }) => {
      state.thread = payload;
    },
    updateReplyId: (state, { payload }) => {
      state.replyId = payload;
    },
    updateSendSeparate: (state, { payload }) => {
      state.sendSeparate = payload;
    },
    updateBulkContactList: (state, { payload }) => {
      state.bulkContactList = payload;
    },
    updateFormTo: (state, { payload }) => {
      state.form.to = payload;
    },
    updateFormCC: (state, { payload }) => {
      state.form.cc = payload;
    },
    updateFormBCC: (state, { payload }) => {
      state.form.bcc = payload;
    },
    updateFormSubject: (state, { payload }) => {
      state.form.subject = payload;
    },
    updateFormContent: (state, { payload }) => {
      state.form.content = payload;
    },
    addFormAttachments: (state, { payload }) => {
      state.form.attachments = [
        ...state.form.attachments,
        payload,
      ];
    },
    removeFormAttachments: (state, { payload }) => {
      const attachments = [...state.form.attachments];

      attachments.splice(payload, 1);

      state.form.attachments = attachments;
    },
    resetForm: (state, { payload }) => {
      state.composeOpen = false;
      state.sendSeparate = false;
      state.bulkContactList = [];
      state.form = initialState.form;
    },
  },
});

export const {
  toggleSidebarOpen,
  toggleComposeOpen,
  updateSearch,
  updateFolders,
  updateActiveFolder,
  updateActiveIndex,
  resetData,
  appendData,
  updateThread,
  updateReplyId,
  updateSendSeparate,
  updateBulkContactList,
  updateFormTo,
  updateFormCC,
  updateFormBCC,
  updateFormSubject,
  updateFormContent,
  addFormAttachments,
  removeFormAttachments,
  resetForm,
} = email.actions;

export default email.reducer;
