import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alias: "",
  domain: "",
  name: "",
  integrations: {},
  templates: [],
  accounts: [],
};

export const companies = createSlice({
  name: "companies",
  initialState,
  reducers: {
    updateCompany: (state, { payload }) => {
      state.alias = payload.alias;
      state.domain = payload.domain;
      state.name = payload.name;
      state.integrations = payload.integrations;
    },
    resetCompanies: (state) => {
      state.alias = initialState.alias;
      state.domain = initialState.domain;
      state.name = initialState.name;
    },
    updateTemplates: (state, { payload }) => {
      state.templates = payload;
    },
    updateAccounts: (state, { payload }) => {
      state.accounts = payload;
    },
  },
});

export const {
  updateCompany,
  resetCompanies,
  updateTemplates,
  updateAccounts,
} = companies.actions;

export default companies.reducer;
