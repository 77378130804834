import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: "activities",
  data: [],
  contact: {},
  total: 0,
  conversation: [],
  trackers: {},
  page: 0,
  pageSize: 25,
  sidebarOpen: false,
};

export const contacts = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    updateTab: (state, { payload }) => {
      state.tab = payload;
    },
    updateData: (state, { payload }) => {
      state.data = payload;
    },
    updateContact: (state, { payload }) => {
      state.contact = payload;
    },
    updateTotal: (state, { payload }) => {
      state.total = payload;
    },
    updateConversation: (state, { payload }) => {
      state.conversation = payload;
    },
    updateTrackers: (state, { payload }) => {
      const trackers = {};

      for (const entry of payload) {
        trackers[entry.mailId] = entry.count;
      }

      state.trackers = trackers;
    },
    updatePage: (state, { payload }) => {
      state.page = payload;
    },
    updatePageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    toggleSidebar: (state, { payload }) => {
      state.sidebarOpen = payload;
    },
  },
});

export const {
  updateTab,
  updateData,
  updateContact,
  updateTotal,
  updateConversation,
  updatePage,
  updateTrackers,
  updatePageSize,
  toggleSidebar,
} = contacts.actions;

export default contacts.reducer;
