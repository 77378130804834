import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarLeftOpen: false,
  sidebarLeftExpand: false,
  sidebarRightOpen: false,
  activeChatCategory: "chats",
  activeChatIndex: 0,
  chats: [
    {
      id: 1,
      fullName: "Robert Benzali",
      header: "Fullstack Engineer",
      about: "From email",
      time: new Date(),
      email: "robertb@gmail.com",
      phone: "+65 8232 8363",
      chat: [
        {
          message: "Hi",
          time: "Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)",
          senderId: 11,
        },
        {
          message: "Hello. How can I help You?",
          time: "Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)",
          senderId: 2,
        },
        {
          message: "Can I get details of my last transaction I made last month?",
          time: "Mon Dec 11 2018 07:46:10 GMT+0000 (GMT)",
          senderId: 11,
        },
        {
          message: "We need to check if we can provide you such information.",
          time: "Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)",
          senderId: 2,
        },
        {
          message: "I will inform you as I get update on this.",
          time: "Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)",
          senderId: 2,
        },
        {
          message: "If it takes long you can mail me at my mail address.",
          time: "Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)",
          senderId: 11,
        },
      ],
    },
    {
      id: 2,
      fullName: "Supirman Gonzales",
      header: "Data Scientist",
      about: "From email",
      time: new Date(),
      email: "supirman@gmail.com",
      phone: "+65 9387 2736",
      chat: [
        {
          message: "How can we help? We're here for you!",
          time: "Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)",
          senderId: 11,
        },
        {
          message: "Hey John, I am looking for the best admin template. Could you please help me to find it out?",
          time: "Mon Dec 10 2018 07:45:23 GMT+0000 (GMT)",
          senderId: 1,
        },
        {
          message: "It should be Bootstrap 5 compatible.",
          time: "Mon Dec 10 2018 07:45:55 GMT+0000 (GMT)",
          senderId: 1,
        },
        {
          message: "Absolutely!",
          time: "Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)",
          senderId: 11,
        },
        {
          message: "Modern admin is the responsive bootstrap 5 admin template.!",
          time: "Mon Dec 10 2018 07:46:05 GMT+0000 (GMT)",
          senderId: 11,
        },
        {
          message: "Looks clean and fresh UI.",
          time: "Mon Dec 10 2018 07:46:23 GMT+0000 (GMT)",
          senderId: 1,
        },
        {
          message: "It's perfect for my next project.",
          time: "Mon Dec 10 2018 07:46:33 GMT+0000 (GMT)",
          senderId: 1,
        },
        {
          message: "How can I purchase it?",
          time: "Mon Dec 10 2018 07:46:43 GMT+0000 (GMT)",
          senderId: 1,
        },
        {
          message: "Thanks, from ThemeForest.",
          time: "Mon Dec 10 2018 07:46:53 GMT+0000 (GMT)",
          senderId: 11,
        },
        {
          message: "I will purchase it for sure. 👍",
          time: "Mon Dec 10 2018 07:46:53 GMT+0000 (GMT)",
          senderId: 1,
        },
      ],
    },
  ],
  contacts: [
    {
      id: 1,
      fullName: "Felecia Rower",
      header: "Frontend Developer",
      about: "From email",
      avatar: require("@src/assets/images/portrait/small/avatar-s-11.jpg").default,
      status: "offline",
      email: "felecia@gmail.com",
      phone: "+65 8363 2736",
    },
    {
      id: 2,
      fullName: "Adalberto Granzin",
      header: "UI/UX Designer",
      about: "From ATS",
      avatar: require("@src/assets/images/portrait/small/avatar-s-11.jpg").default,
      status: "busy",
      email: "adalberto@gmail.com",
      phone: "+65 9282 7373",
    },
  ],
};

export const chat = createSlice({
  name: "chat",
  initialState,
  reducers: {
    updateActiveChatCategory: (state, { payload }) => {
      state.activeChatCategory = payload;
    },
    updateActiveChatIndex: (state, { payload }) => {
      state.activeChatIndex = payload;
    },
    toggleSidebarLeftOpen: (state, { payload }) => {
      state.sidebarLeftOpen = payload;
    },
    toggleSidebarLeftExpand: (state, { payload }) => {
      state.sidebarLeftExpand = payload;
    },
    toggleSidebarRightOpen: (state, { payload }) => {
      state.sidebarRightOpen = payload;
    },
  },
});

export const {
  updateActiveChatCategory,
  updateActiveChatIndex,
  toggleSidebarLeftOpen,
  toggleSidebarLeftExpand,
  toggleSidebarRightOpen,
} = chat.actions;

export default chat.reducer;
