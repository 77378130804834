import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: "fitment",
  talent: {},
  distance: {},
};

export const talents = createSlice({
  name: "talents",
  initialState,
  reducers: {
    updateTab: (state, { payload }) => {
      state.tab = payload;
    },
    updateTalent: (state, { payload }) => {
      state.talent = payload;
    },
    updateDistance: (state, { payload }) => {
      state.distance = payload;
    },
    resetTalents: (state) => {
      state.tab = initialState.tab;
      state.talent = initialState.talent;
      state.distance = initialState.distance;
    },
  },
});

export const {
  updateTab,
  updateTalent,
  updateDistance,
  resetTalents,
} = talents.actions;

export default talents.reducer;
