import { createSlice } from "@reduxjs/toolkit";

import themeConfig from "@configs/themeConfig";

const skin = window.localStorage.getItem("skin");
const direction = window.localStorage.getItem("direction");
const menuCollapsed = window.localStorage.getItem("menuCollapsed");

const initialState = {
  skin: skin ? JSON.parse(skin) : themeConfig.layout.skin,
  isRTL: direction ? JSON.parse(direction) : themeConfig.layout.isRTL,
  layout: themeConfig.layout.type,
  lastLayout: themeConfig.layout.type,
  menuCollapsed: menuCollapsed ? JSON.parse(menuCollapsed) : themeConfig.layout.menu.isCollapsed,
  footerType: themeConfig.layout.footer.type,
  navbarType: themeConfig.layout.navbar.type,
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  routerTransition: themeConfig.layout.routerTransition,
  navbarColor: themeConfig.layout.navbar.backgroundColor,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    handleRTL: (state, { payload }) => {
      state.isRTL = payload;
      window.localStorage.setItem("direction", JSON.stringify(payload));
    },
    handleSkin: (state, { payload }) => {
      state.skin = payload;
      window.localStorage.setItem("skin", JSON.stringify(payload));
    },
    handleLayout: (state, { payload }) => {
      state.layout = payload;
    },
    handleFooterType: (state, { payload }) => {
      state.footerType = payload;
    },
    handleNavbarType: (state, { payload }) => {
      state.navbarType = payload;
    },
    handleMenuHidden: (state, { payload }) => {
      state.menuHidden = payload;
    },
    handleLastLayout: (state, { payload }) => {
      state.lastLayout = payload;
    },
    handleNavbarColor: (state, { payload }) => {
      state.navbarColor = payload;
    },
    handleContentWidth: (state, { payload }) => {
      state.contentWidth = payload;
    },
    handleMenuCollapsed: (state, { payload }) => {
      state.menuCollapsed = payload;
      window.localStorage.setItem("menuCollapsed", JSON.stringify(payload));
    },
    handleRouterTransition: (state, { payload }) => {
      state.routerTransition = payload;
    },
  },
});

export const {
  handleRTL,
  handleSkin,
  handleLayout,
  handleLastLayout,
  handleMenuHidden,
  handleNavbarType,
  handleFooterType,
  handleNavbarColor,
  handleContentWidth,
  handleMenuCollapsed,
  handleRouterTransition,
} = layoutSlice.actions;

export default layoutSlice.reducer;
